<template>
  <div class="about pa-2">

    <page-card
        cardTitle="Help and FAQ"
        cardTitleIcon="mdi-help-box"
        cardSubtitle="From booklet building to Wedding Walls and order changes, this Help & FAQ section will guide you through the entire process."
    >

    <v-card elevation="2" class="px-2 mb-2">

        <v-card-text>
            <div class="subtitle-1">
                <strong>Building Booklets</strong>
                <p> </p>
            </div>
        </v-card-text>

            <v-expansion-panels class="pb-2">
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <strong>The 5 Simple Steps to Building a Booklet</strong>
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                            1. Enter the details for your front cover in the Getting Started section.<br>
                            2. Choose your theme.<br>
                            3. Write your introduction for the inside front cover and upload your favourite photograph.<br>
                            4. Choose your six activities, decide the order in which you want them to appear in the booklet and follow the instructions to build the activities.<br>
                            5. Write your farewell message for the inside back cover.<br>
                            </p>
                        </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <strong>Do I need to prepare anything before starting?</strong>
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                            The short answer to this is ‘no’; you can build as you go. You may want to have some of your favourite photographs ready before starting to build the booklet, but you can just as easily upload your photographs as you go along building the activities.
                            </p>
                        </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <strong>Using the Preview</strong>
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                            The Booklet Builder has a Preview function that allows you to check out how your booklet is looking as you build and add content. We recommend using the Preview as you choose and create each activity, so you can watch your booklets and experience take shape, each step at a time.
                            </p>
                        </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <strong>Enabling Spell Checker</strong>
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                            We recommend that you enable the spell checker in your internet browser whilst you are working in the Booklet Builder.  It will help reduce the risk of typos in your booklet.
                            </p>
                        </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <strong>Choosing a Theme</strong>
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                            We recommend having a good look at our <a href="https://www.cherrytopweddings.com/choose-your-design/" target="_blank"> Designs page</a> where you can search by colour and style to narrow down your choices. Your theme is your chosen design and colourway.
                            </p>
                            <p>
                            When you have chosen your favourite, return to the Booklet Builder.
                            </p>
                            <p>
                            When selecting your theme, we advise you to choose a colour and style which complement your wedding aesthetics, as well as reflecting your personalities.
                            </p>
                            <p>
                            When you have decided which one you want to use, follow the instructions on the Theme section on the menu bar.
                            </p>
                        </v-expansion-panel-content>
                </v-expansion-panel>


                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <strong>Choosing Your Activities</strong>
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                            There are thirteen activities available, of which you need to choose six. Which ones you choose is entirely up to you, but we suggest you have a broad range so that the activities will have the widest possible appeal to your guests.
                            </p>
                            <p>
                            The activities all serve a slightly different purpose, so it is worth taking your time to choose in order to ensure a good balance. We suggest you check out the ‘All About Activities’ section of this Help area for more detail about each of the activities.
                            </p>
                            <p>
                            When you come to choose your first activity, on page 3 of the booklet, the drop-down list will contain all thirteen activities. As you select your activities, they will no longer appear on subsequent drop-down lists.
                            </p>
                        </v-expansion-panel-content>
                </v-expansion-panel>


                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <strong>Saving Your Work</strong>
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                            There is a Save button at the end of each section which we recommend using each time a change is made. You can enter and leave the Booklet Builder as many times as you wish, happy in the knowledge that your work is totally secure.
                            </p>
                        </v-expansion-panel-content>
                </v-expansion-panel>


                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <strong>Can I change my theme and activities?</strong>
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                            You are able to change your theme at any time during the booklet-building process. Just go to the Theme area via the menu and select a different theme. However, once the booklet has been submitted it will no longer be possible to change the theme.
                            </p>
                            <p>
                            Similarly, activities can also be changed at any time during the booklet-building process. To change an activity, just go to the page with the activity you wish to change and select a different activity from the drop-down list. Build the new activity and press Save.
                            </p>
                            <p>
                            The data you entered for the activity you wish to be removed has not been lost, and should you subsequently re-choose that activity for another page it will still be there for you.
                            </p>
                        </v-expansion-panel-content>
                </v-expansion-panel>



            </v-expansion-panels>

    </v-card>

    <v-card elevation="2" class="px-2 mb-2">

        <v-card-text>
            <div class="subtitle-1">
                <strong>My Order</strong>
                    <p> </p>
            </div>
        </v-card-text>

        <v-card elevation="2" class="px-2 mb-2">

            <v-card-text>
                <div class="subtitle-1">
                    <strong>Changing My Order</strong>
                </div>
            </v-card-text>

                <v-expansion-panels class="pb-2">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <strong>Can I order more booklets?</strong>
                        </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                Yes, of course. If you wish to order more booklets prior to submission, please call us on 01872 713344 or email us at help@cherrytopweddings.com
								<br>
								If you wish to order more booklets after submission, this may or may not be possible, depending on the stage of production. Once again, please call us on 01872 713344 or email us at help@cherrytopweddings.com where we will be able to advise you further.
                                </p>
                                <p>
                                If you wish to move to another package which offers more copies <strong>after</strong> your booklet has been completed, submitted, checked and sent for production, please call us on 01872 713344 to arrange this. You will be charged at the difference between your original package price and the cost of the new package. An additional flat fee of £5.00 will also apply, to cover handling and administration.
                                </p>
                            </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <strong>Can I change to the Custom Covers option?</strong>
                        </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                Yes, of course, as long as your order has not yet been completed and submitted. Please call us on 01872 713344 so we can take your payment for this (£10) and change your settings so that the next time you log into the Booklet Builder you will be able to upload your own covers.
                                </p>
                            </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <strong>How do I change my delivery address?</strong>
                        </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                Please email us help@cherrytopweddings.com or telephone us on 01872 713344.
                                </p>
                            </v-expansion-panel-content>
                    </v-expansion-panel>


                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <strong>Can I reduce my order?</strong>
                        </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                Yes, you may reduce the number of booklets required up until they have been submitted and have entered the production stage. You will of course be refunded the difference between the original number and your new chosen number. There is a minimum order of 20, so it is not possible to reduce the number to below 20.
                                </p>
                                <p>
                                You may cancel your order at any time <strong>until</strong> your booklet has been completed, submitted, and sent for production.
                                </p>
                                <p>
                                In either case, please telephone us on 01872 713344 to discuss.
                                </p>
                            </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <strong>How can I change my wedding date?</strong>
                        </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    The date of your wedding has been taken from your original order. If this date has changed,
                                     please let us know by sending an email to:
                                     <br> <br>help@cherrytopweddings.com 
                                     <br><br>Or by giving us a call on:
                                     <br><br>01872 713344
                                </p>
                                
                            </v-expansion-panel-content>
                    </v-expansion-panel>
                    


                </v-expansion-panels>

        </v-card>

        <v-card elevation="2" class="px-2 mb-2">

            <v-card-text>
                <div class="subtitle-1">
                    <strong>Submitting my Booklet</strong>
                </div>
            </v-card-text>

             
    <v-expansion-panels class="pb-2">          
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <strong>Can I make alterations to my booklet after it has been sent for production?</strong>
                        </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                Regrettably, once the booklet has been sent for production, changes are no longer possible.
                                </p>
                            </v-expansion-panel-content>
                    </v-expansion-panel>


                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <strong>Can I order additional copies after submission?</strong>
                        </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                If you wish to order more booklets after submission, this may or may not be possible, depending on the stage of production. Please call us on 01872 713344 or email us at help@cherrytopweddings.com where we will be able to advise you further.
                                </p>
                            </v-expansion-panel-content>
                    </v-expansion-panel>



                </v-expansion-panels>

        </v-card>

        <v-card elevation="2" class="px-2 mb-2">

            <v-card-text>
                <div class="subtitle-1">
                    <strong>Delivery</strong>
                </div>
            </v-card-text>

                <v-expansion-panels class="pb-2">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <strong>How are the booklets delivered?</strong>
                        </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                You will receive your booklets, wrapped and protected in a box, delivered by courier/post to the address of your choice. We will email you to let you know your parcel is on its way.
                                </p>
                            </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <strong>When will my booklets be delivered?</strong>
                        </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                You should receive your order approximately 7-10 days after you submit the final booklet to us.
                                </p>
                            </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <strong>Can I change the delivery address?</strong>
                        </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                Yes, please email us help@cherrytopwedding.com or telephone us on 01872 713344 to change the delivery address at any time before your booklet has been submitted.
                                </p>
                            </v-expansion-panel-content>
                    </v-expansion-panel>


                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <strong>What is the minimum amount of notice I need to give CherryTop Weddings to check, produce and send my booklets before my wedding?</strong>
                        </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                We would advise a minimum of three weeks, however in extenuating circumstances we might be able to shorten this period. Please email us help@cherrytopwedding.com or telephone us on 01872 713344 to discuss.
                                </p>
                            </v-expansion-panel-content>
                    </v-expansion-panel>


                 


                </v-expansion-panels>

        </v-card>


    <br>
    </v-card>

    <v-card elevation="2" class="px-2 mb-2">

        <v-card-text>
            <div class="subtitle-1">
                <strong>All About Photos</strong>
                <p> </p>
            </div>
        </v-card-text>

            <v-expansion-panels class="pb-2">
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <strong>What photos should I choose?</strong>
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                            As well as a fun form of entertainment on your wedding day, the booklet is also intended to be a souvenir of the occasion which people will keep. For this reason, we recommend you choose your favourite photos of you together as a couple. Depending on which activities you choose, you will need to select between six and eight photos. We advise you to use your favourite photo on page 2 as this has the largest available space for a photo.
                            </p>
                            <p>
                            The booklets are being commercially printed using high resolution graphics, so it is important you choose photos of the highest quality. Pixelated or poor-quality photos will diminish the overall impact of your booklet. For those with a knowledge of photography and picture editing, we recommend a minimum dpi of 300.
                            </p>
                        </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <strong>What file types can I use?</strong>
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                            The Booklet Builder will accept the following file types for your photos.
                            </p>
                            <p>
                            •	.jpg/jpeg <br>
                            •	.tif <br>
                            •	.gif <br>
                            •	.png <br>
                            </p>
                            <p>
                            However, we recommend that you use jpg/jpeg file.
                            </p>
                        </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <strong>What size should I use?</strong>
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                            There are three photo sizes used in the booklet.
                            </p>
                            <p>
                            1.	The page 2 (inside front cover) photo - 92mm x 118mm (w x h) <br>
                            2.	Activity page photos - 92mm x 85mm (w x h) <br>
                            3.	The page 11 (inside front cover) photo - 92mm x 68 mm (w x h) <br>
                            </p>
                            <p>
                            When preparing your photos for upload to the Booklet Builder, it would be best if your photo matched or roughly matched these sizes.  See how to crop your photos below for advice on how to do this.
                            </p>
                        </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <strong>How do I crop my photos?</strong>
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                            You can use photo editing software on your computer to crop your images or you can use the simple Croppola web tool that is free and available to everyone.  The steps are:
                            </p>
                            <p>
                            •	Go to <a href="https://croppola.com/" target="_blank">croppola.com</a><br>
                            •	Drag your image onto the "Drop your photo here..." drop zone. Or select your photo via your folders. <br>
                            •	Immediately you'll see the photo in the editor. On the right, switch to "Manual" instead of "Auto". <br>
                            •	Set the width and height for the photo using the sizes shown above. <br>
                            •	Then move the cropping area around to ensure that your image is centralised. <br>
                            •	When you're happy with the image, click the "Download Crop" button. <br>
                            •	The cropped image should download to your browser's download location. <br>
                            •	Upload this photo into your booklet via the Booklet Builder.
                            </p>
                        </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <strong>How do I optimise my photos?</strong>
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                            This is a huge topic which can take years to master. Luckily there many software programs and online tools to help you make some simple changes to enhance your photos.
                            </p>
                            <p>
                            If you are not used to editing your own photos, then we recommend reading this guide and following method 1 with using Pixlr X.
                            </p>
                            <p>
                            <a href="https://www.wikihow.com/Improve-JPEG-Image-Quality" target="_blank">Wikihow.com Image Quality</a>
                            </p>
                            <p>
                            If you only use the clarity and sharpen steps at 6 and 7, you will make a significant improvement to your photos.
                            </p>
                        </v-expansion-panel-content>
                </v-expansion-panel>

            </v-expansion-panels>

    </v-card>

    <v-card elevation="2" class="px-2 mb-2">

        <v-card-text>
            <div class="subtitle-1">
                <strong>All About Activities</strong>
                <p> </p>
            </div>
        </v-card-text>

            <v-expansion-panels class="pb-2">
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <strong>Which activities should I choose?</strong>
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                                There are thirteen activities available, of which you need to choose six. Which ones you choose is entirely up to you, but we suggest
                                you have a broad range so that the activities will have the widest possible appeal to your guests.
                                The thirteen available activities are:
                            </p>

                            <p>
                            <strong>Love Letters.</strong> Secret messages, a twist on a classic puzzle, and even a riddle. This puzzling activity is ready to go and
                            requires no personalisation from you other than a photo and a caption. Sure to have your guests swapping answers and sneaking peeks, this is
                            a wordsearch like no other. The words to be found in the heart-shaped grid are all linked to weddings and marriage, and the remaining letters
                            spell out a tricky riddle that will have the guests chatting, conferring and hopefully not cheating!
                            </p>

                            <p>
                            <strong>The Happy Couple Quiz.</strong> We’ve probably all seen or at least know how the Mr and Mrs Quizzes on the TV work. Here is your chance to make one for yourselves, but the question is, how well do your guests know you? This activity will require some effort. You’ll need to think of six fun questions about yourselves, where the answer is one or the other of you. The questions can be as sensible or wacky as you wish! Not only will your guests have some fun, but they will also learn a little bit about you. You will also need to upload a photo and caption with this activity.
                            </p>

                            <p>
                            <strong>The Round the World Wedding Quiz.</strong> The world would be a dull place if we all liked the same thing, goes the saying. This quiz is based on wedding traditions around the world, and trust us, they are not dull! This activity is ready to go and requires no personalisation whatsoever. It’s multiple choice with 12 questions all based on wedding traditions from around the world. If you or your guests think they have been to strange weddings before, think again!
                            </p>

                            <p>
                            <strong>Our Secret Message.</strong> Breaking codes, discovering hidden messages, deciphering symbols, if that’s your cup of tea, tick the box! This sneaky activity requires a small amount of effort including the uploading of a photo and caption. You’ll write a secret message for your guests, which will be converted into a secret code which the guests can decipher using the symbols on the page in the booklet. You can of course write any message you wish. However, whether your guests can crack your code and uncover the message is a different story.
                            </p>

                            <p>
                            <strong>Say What You See.</strong> This activity does what it says on the tin. Clichés aside though, this cheeky challenge is made up of 6 visual puzzles which depict a word, phrase or well-known saying linked to weddings. A certain amount of lateral thinking is required, and this activity will certainly generate a huge amount of debate around the tables. There is no personalisation needed, aside from uploading a photo and caption. Let the brain-teasing begin!
                            </p>

                            <p>
                            <strong>The Limerick Challenge.</strong> I am a limerick game, and creating laughter is my aim!  Your guests are given the opening line of a limerick and must use their wit, imagination and creativity to complete the remaining lines. You choose from several possible opening lines that all end with words with rhyming potential, no silver or oranges here! Again, there is no personalisation needed, just a chosen photo and caption.
                            </p>

                            <p>
                            <strong>Words of Wisdom.</strong> Also known as mad libs, this improvising little activity gives your guests a piece of text which requires them to fill in blanks to create sentences. Trust us, it’s more exciting than it sounds! The result of their efforts should be a fun source of advice to help you have a long and happy marriage. This activity requires no effort from you - not even a photo or caption!
                            </p>

                            <p>
                            <strong>Find the Guest Challenge.</strong> The ultimate icebreaker, requiring your guests to speak to as many other guests as they can. Points can be won for identifying guests that match the criteria on the page, from finding guests who have climbed mountains, to ones with a certain number of siblings. It is a great way of getting guests who don’t know each other to mingle and talk to each other, just watch out for that one uncle who will tell you he’s done everything, everyone knows one! This activity is ready to go and requires no personalisation from you other than a photo and caption.
                            </p>

                            <p>
                            <strong>Wedding Speech Bingo.</strong> Cue hilarious results, we have created a bingo card for the page which contains popular words and phrases used in wedding speeches.  As guests listen to the speeches, they can cross off any that are said by the speakers. The question is will anyone get a line or a full house? N.B. As guests will need to shout out when they get a line or full house, we recommend you do not choose this activity if you do not want to risk the speeches being interrupted at a crucial or sentimental moment! This activity requires no effort from you - not even a photo or caption. Eyes down!
                            </p>

                            <p>
                            <strong>True or False?</strong> You will miss an opportunity if you don’t choose this activity. Now was that true or false? We’ll let you decide. For this one, you’ll need to think of 3 fun or bizarre facts about yourselves. We will then mix these up with 3 falsehoods, which you can either write yourselves or choose from a list we will provide (some are tame, some are wild!). Your guests then have to decide which of the 6 facts on the page are true, and which are false. This activity requires a little bit of effort, as well as a photo and a caption.
                            </p>

                            <p>
                            <strong>Picture This.</strong> One of our favourites, this creative game sees you write down a brief description of a scene or place that is very special to you both. Your guests are then encouraged to draw this scene in the frame on the page. Drawing talents are absolutely not required, the less talented ones often make for the more hilarious submissions!  This activity requires a little bit of effort from you but will be rewarded with mementos to treasure forever, or at the very least, a source of amusement forever!
                            </p>

                            <p>
                            <strong>Popping the Question.</strong> You need to think of 5 questions about yourselves where the answer to each question is a number. These 5 answers will be put on balloons on the page in the booklet, along with an extra number which was not an answer to one of your questions. The guests’ job is to work out which balloon is left by popping the 5 balloons containing the correct answers to your questions. It’s actually a lot simpler than it sounds, and it’ll have your guests questioning their choices and debating with each other before heading to the answers. This activity requires a little bit of effort, as well as a photo and a caption.
                            </p>

                            <p>
                            <strong>Where Were We?</strong> Who needs Insta, we’ve got Ana. Yes, this is an anagram game. The anagrams though will be of places you have visited or are important to you. Once you’ve thought of 6 places, your answers will be converted to anagrams which the guests must then unravel. Did your colleagues know you’d been to Outer Mongolia? They do now! You will also be able to give a small hint to help. This activity requires a little bit of effort, as well as a photo and a caption.
                            </p>

                        </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <strong>Which are the 6 most popular activities?</strong>
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                            Whilst all weddings are of course unique, you might be interested to know that the current top six favourite activities are:
                            </p>
                            <p>
                            1.	The Happy Couple <br>
                            2.	Words of Wisdom <br>
                            3.	Popping the Question <br>
                            4.	The Limerick <br>
                            5.	Picture This <br>
                            6.	True or False? <br>
                            </p>
                        </v-expansion-panel-content>
                </v-expansion-panel>

                

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <strong>Which activities require the least amount of preparation?</strong>
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                            We understand that you may well be short of time in the run-up to their wedding, so we are pleased to offer some activities which require very little personalisation or preparation from you, other than a photo and caption. These activities are:
                            </p>
                            <p>
                            Love Letters
                            </p>
                            <p>
                            Round the World Wedding Quiz
                            </p>
                            <p>
                            The Limerick
                            </p>
                            <p>
                            Words of Wisdom
                            </p>
                            <p>
                            Find the Guest
                            </p>
                            <p>
                            Wedding Speech Bingo
                            </p>
                            <p>
                            Say What You See
                            </p>
                        </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <strong>Which activities require the most preparation?</strong>
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                            No activity requires a great deal of preparation - the most you will have to do is think up six questions and answers. However, the following activities all require a little bit of effort:
                            </p>
                            <p>
                            The Happy Couple Quiz
                            </p>
                            <p>
                            True or False?
                            </p>
                            <p>
                            Popping the Question
                            </p>
                            <p>
                            Our Secret Message
                            </p>
                            <p>
                            Picture This
                            </p>
                            <p>
                            Where Were We?
                            </p>
                        </v-expansion-panel-content>
                </v-expansion-panel>


            


            </v-expansion-panels>

    </v-card>



    </page-card>

  </div>

</template>

<script>
import PageCard from "@/components/PageCard";

export default {
  name: 'Help',
  components: {
    PageCard
  },
}
</script>
